/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Images
import menuOpen from 'img/snel_menu_open.svg'
import menuClose from 'img/snel_menu_close.svg'
import ChevronBlueDown from 'img/snel_blue_chevron_down.svg'
import ChevronRedRight from 'img/snel_red_chevron_right.svg'

// Components
import CustomLink from 'components/CustomLink'
import { Paragraph, Li } from 'components/StyledComponents'
import SnelLogo from 'img/logo.inline.svg'

import { isBrowser } from '../services/language'

const StyledCustomLink = styled(CustomLink)`
  z-index: 10;

  @media (max-width: 991px) {
    display: flex;
    justify-content: space-between;
    font-size: 18px !important;

    .rotated {
      transform: rotate(90deg);
    }

    &:hover {
    color: ${(props) => props.theme.color.main};
   }
  }


  &:hover {
    color: ${(props) => props.theme.color.main};
    text-decoration: underline !important;
  }

  ${(props) =>
    props.mobile
      ? `
    &[aria-current]{
      border-bottom: 1px solid ${props.theme.color.contrast} !important;
    }

    ${Paragraph} {
      font-size: 20px;
    }
  `
      : `
    ${Paragraph} {
      @media (max-width: 1199px) {
        font-size: 16px;
      }
    }
  `}
`

const HeaderWrapper = styled.nav`
  position: relative;
  z-index: 10;
`

const Logo = styled.img`
  width: 214px;
  height: auto;

  @media (max-width: 991px) {
    width: 200px;
  }
`

const MobileMenu = styled.div`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;

  ul {
    width: 100%;
    padding-left: 0;
  }

  ${Li} {
    padding-right: 60px !important;
    padding-left: 60px !important;
    flex-wrap: wrap;
  }

  ${StyledCustomLink} {
    
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
`

const CloseMenu = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
`

const ThinBanner = styled.div`
  @media (max-width: 991px) {
    display: none;
  }
`

const Header = () => {
  const [isCollapsed, setCollapsed] = useState(true)

  const {
    header: {
      header: {
        links: menuItems,
        informationheader: { phonenumber },
        logo: {
          localFile: { publicURL: logo },
        },
        socialLinks: { social: socialList },
      },
    },
  } = useStaticQuery(graphql`
    {
      header: wpComponent(databaseId: { eq: 10 }) {
        header{
          links {
            link {
              target
              title
              url
            }
            submenu {
              link {
                target
                title
                url
              }
            }
            showAsButton
          }
          informationheader {
            email
            phonenumber
          }
          logo {
            localFile {
              publicURL
            }
          }
          socialLinks {
            social {
              link {
                target
                title
                url
              }
              icon {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper className="header position-relative">
      <ThinBanner className="color-background-main">
        <div className="container">
          <div className="d-flex justify-content-between">
            <div className="d-flex justify-content-start">
              {socialList.map((social, index) => (
                <CustomLink
                  external
                  newPage
                  className="pr-3 py-2"
                  key={index}
                  to={social.link.url}
                >
                  <img src={social.icon.localFile.publicURL} alt="" />
                </CustomLink>
              ))}
            </div>
            <div className="d-flex align-items-center">
              <CustomLink
                external
                to={`tel:${phonenumber}`}
              >
                <Paragraph className="color-text-light font-size-m">
                  <strong>Bel ons</strong> {phonenumber}
                </Paragraph>
              </CustomLink>
            </div>
          </div>
        </div>
      </ThinBanner>
      <div className="navbar-container container d-flex flex-wrap justify-content-between align-items-center py-4">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <Logo src={SnelLogo} alt="" />
          </Link>
        </div>

        <button
          aria-label="close-open-menu"
          type="button"
          onClick={() => setCollapsed(!isCollapsed)}
          className={`navbar-collapse-button${isCollapsed ? ' navbar-collapse-button-collapsed' : ''
            } text-center d-block d-lg-none`}
        >
          {isCollapsed ? (
            <img src={menuOpen} alt="" />
          ) : (
            <CloseMenu src={menuClose} alt="" />
          )}
        </button>

        <HeaderMenuMobile
          phonenumber={phonenumber}
          menuItems={menuItems}
          isCollapsed={isCollapsed}
          setCollapse={setCollapsed}
        />
        <HeaderMenuDesktop phonenumber={phonenumber} menuItems={menuItems} />
      </div>
    </HeaderWrapper>
  )
}

const HeaderMenuMobile = ({ isCollapsed, menuItems, phonenumber }) => (
  <CSSTransition
    in={!isCollapsed}
    timeout={0}
    unmountOnExit
    classNames="header-menu"
  >
    <MobileMenu className="navbar-menu color-background-main position-absolute d-flex justify-content-center align-items-center">
      <ul className="navbar-items color-background-main">
        <HeaderMenuItems
          mobile
          phonenumber={phonenumber}
          menuItems={menuItems}
        />
      </ul>
    </MobileMenu>
  </CSSTransition>
)

const HeaderMenuDesktop = ({ menuItems, phonenumber }) => (
  <ul className="navbar-items mb-0 d-none d-lg-flex">
    <HeaderMenuItems menuItems={menuItems} phonenumber={phonenumber} />
  </ul>
)

const Submenu = styled(motion.div)`
  width: 200px;
  position: absolute;
  left: 0;
  display: none;
  top: 20px;
  z-index: 2;
  padding-top: 28px;

  @media screen and (max-width: 991px) {
    padding-top: 0;
    top: 0;
  }

  .wrap {
      @media (min-width: 992px){
      background: rgb(255, 255, 255);
      min-width: 10rem;
      padding: 0.5rem 0;
      margin: 0.125rem 0 0;
      font-size: 1rem;
      color: #212529;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, .15);
      border-radius: 0.25rem;

      a {
        padding: 0.25rem 1.5rem;
      }

      a[aria-current] {
        color: ${(props) => props.theme.color.main} !important;
        display: flex;
        align-items: center;
        
      }
      a {
        &:hover {
          text-decoration: underline;
          background-color: #f3f3f3;
        }
      }
    }

    @media (max-width: 991px) {
      padding-bottom: 1rem;
    }
  }

  &:hover {
    display: block;
  }

  a {
    text-transform: unset;
    display: block;
    color: ${(props) => props.theme.color.text.main} !important;
  }

  @media (max-width: 991.98px) {
    position: relative;
    width: 100%;

    a:first-child {
      margin-top: 0 !important;
    }

    a {
      font-size: 18px !important;
      padding: 0.25rem 10px;
      color: ${(props) => props.theme.color.text.light};
    }
  }
`

const HeaderMenuItems = ({ mobile, menuItems, phonenumber }) => {
  const [currentIndex, setCurrentIndex] = useState(-1)
  const isMobile = isBrowser() && window.innerWidth < 992

  const handleToggle = (index) => {
    if (document !== null) {
      document.getElementById(`image-${index}`).classList.toggle('rotated')
      setCurrentIndex(index === currentIndex ? -1 : index)
    }
  }

  return (
    <>
      {menuItems.map((menuItem, index) => {
        const isActive = currentIndex === index

        return (
          <Li
            onMouseEnter={() => !isMobile && setCurrentIndex(index)}
            onMouseLeave={() => !isMobile && setCurrentIndex(-1)}
            key={index}
            className={`${menuItem.showAsButton ? 'menubtn' : ''} px-3 d-flex justify-content-center align-items-center position-relative`}
          >
            <StyledCustomLink
              mobile={mobile}
              className={`font-size-m ${mobile ? `color-text-light` : `color-text-main`}`}
              to={menuItem.link.url}
            >
              {menuItem.link.title}

              {!isMobile && menuItem.submenu && (
                <img className="pl-2" src={ChevronBlueDown} alt="" />
              )}
              {isMobile && menuItem.submenu && (
                <img
                  id={`image-${index}`}
                  className="pl-2"
                  src={ChevronRedRight}
                  alt=""
                  onClick={() => handleToggle(index)}
                />
              )}
            </StyledCustomLink>
            {menuItem.submenu && (
              <Submenu
                animate={isActive ? 'open' : 'collapsed'}
                exit="collapsed"
                variants={{
                  open: { display: 'flex' },
                  collapsed: { display: 'none' }
                }}
                initial="false"
              >
                <div className="wrap">
                  {menuItem.submenu.map((subItem, subIndex) => (
                    <CustomLink
                      key={subIndex}
                      to={subItem.link.url}
                      className="font-size-m color-text-main"
                    >
                      {subItem.link.title}
                    </CustomLink>

                  ))}
                </div>
              </Submenu>
            )}
          </Li>
        )
      })}
      <CustomLink
        external
        to={`tel:${phonenumber}`}
        className="d-flex d-lg-none mx-5 pt-4 justify-content-center align-items-center"
      >
        <Paragraph className="mx-1 w-100 d-flex justify-content-center py-3 color-text-light font-size-xl font-weight-xl color-background-striking">
          {phonenumber}
        </Paragraph>
      </CustomLink>
    </>
  )
}

export default Header
